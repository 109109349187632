<template>
  <div>
    <CRow>
      <CCol sm="4">
        <h4 class="text-uppercase">Create Bill Settlement</h4>
      </CCol>
      <CCol sm="8"> </CCol>
    </CRow>
    <CRow>
      <CCol sm="8">
        <CCard>
          <CRow>
            <CCol sm="12">
              <ul class="nav nav-tabs" id="CAppTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    :class="activeTab === 1 ? 'active' : ''"
                    id="home-tab"
                    data-toggle="tab"
                    href="javascript:void(0)"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                    >Customer & Bill Information</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    :class="activeTab === 2 ? 'active' : ''"
                    id="discount-tab"
                    data-toggle="tab"
                    href="javascript:void(0)"
                    role="tab"
                    aria-controls="discount"
                    aria-selected="false"
                    >Discount & EMI Information</a
                  >
                </li>
                <li class="nav-item" role="presentation">
                  <a
                    class="nav-link"
                    :class="activeTab === 3 ? 'active' : ''"
                    id="contact-tab"
                    data-toggle="tab"
                    href="javascript:void(0)"
                    role="tab"
                    aria-controls="contact"
                    aria-selected="false"
                    >Attachments</a
                  >
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div
                  class="tab-pane fade"
                  :class="activeTab === 1 ? 'show active' : ''"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <CustomerBillInfo
                    v-if="activeTab === 1"
                    :settlement="settlement"
                    @formSubmitted="handleFormSubmit"
                  />
                </div>
                <div
                  class="tab-pane fade"
                  :class="activeTab === 2 ? 'show active' : ''"
                  id="discount"
                  role="tabpanel"
                  aria-labelledby="discount-tab"
                >
                  <DiscountInfo
                    v-if="activeTab === 2"
                    :settlement="settlement"
                    @formSubmitted="handleFormSubmit"
                  />
                </div>
                <div
                  class="tab-pane fade"
                  :class="activeTab === 3 ? 'show active' : ''"
                  id="contact"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  <Attachments
                    v-if="activeTab === 3"
                    :settlement="settlement"
                    @formSubmitted="handleFormSubmit"
                  />
                </div>
              </div>
            </CCol>
          </CRow>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const CustomerBillInfo = () =>
  import(/* webpackChunkName: "CustomerBillInfo" */ "./tabs/CustomerBillInfo");
const DiscountInfo = () =>
  import(/* webpackChunkName: "DiscountInfo" */ "./tabs/DiscountInfo");
const Attachments = () =>
  import(/* webpackChunkName: "Attachments" */ "./tabs/Attachments");

export default {
  name: "CreateBillSettlementComp",
  components: {
    CustomerBillInfo,
    DiscountInfo,
    Attachments,
  },
  data() {
    return {
      activeTab: 1,
      settlement: {
        customer_id: "",
        customer_account_id: "",
        discount_type: 'percent',
        discount_value: 0,
        account_number: "",
        is_emi: false,
        bill_ids: [],
        number_of_installment: 0,
        total_amount: 0,
        installment_period: "MONTHLY",
        proposed_by:'',
        start_date:"",
      },
    };
  },
  methods: {
    handleFormSubmit({ data, tab }) {
      this.settlement = data;
      this.activeTab = tab;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: none;
  padding: 40px 30px;
  color: #000;
  label {
    font-weight: 600;
  }
}
div {
  h4 {
    font-size: 15px;
    font-weight: bold !important;
    color: #000;
  }
}
#CAppTab {
  .nav-item {
    .nav-link {
      color: #000 !important;
      font-weight: 600;
    }
  }
}
</style>
